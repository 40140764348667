var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"color":"primary","dark":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v(" Create Group ")],1)]}}]),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Create Group")]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-4",on:{"click":function($event){$event.stopPropagation();_vm.isDialogVisible = false}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"shrink",attrs:{"items":_vm.facilities,"label":"Select a Facility","item-value":"id","item-text":"name","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('small',{staticClass:"ml-2"},[_vm._v("("),_c('b',[_vm._v(_vm._s(item.location)+" ")]),_vm._v(")")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('small',{staticClass:"ml-2"},[_vm._v("("),_c('b',[_vm._v(_vm._s(item.location)+" ")]),_vm._v(")")])]}}]),model:{value:(_vm.formData.facility_id),callback:function ($$v) {_vm.$set(_vm.formData, "facility_id", $$v)},expression:"formData.facility_id"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"text--black"},[_vm._v("Add ungrouped recipients within")]),_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(_vm.formData.distance),callback:function ($$v) {_vm.$set(_vm.formData, "distance", $$v)},expression:"formData.distance"}},[_c('v-radio',{attrs:{"label":"5kms","value":"5"}}),_c('v-radio',{attrs:{"label":"10kms","value":"10"}}),_c('v-radio',{attrs:{"label":"15kms","value":"15"}}),_c('v-radio',{attrs:{"label":"20kms","value":"20"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"pa-6 float-right",attrs:{"color":"primary","rounded":""},on:{"click":_vm.handleFindBranch}},[_vm._v(" Find Branches ")])],1)],1),_c('v-divider'),(_vm.branches.length > 0)?_c('div',{staticClass:"mt-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Group Name","placeholder":"Group Name","hide-details":"auto","dense":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.formData.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.formData, "date", $event)},"update:return-value":function($event){return _vm.$set(_vm.formData, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Picker in dialog","prepend-icon":"mdi-calendar","dense":"","readonly":"","outlined":""},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}},'v-text-field',attrs,false),on))]}}],null,false,3683990890),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.formData.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.branchesColumns,"items":_vm.branches,"server-items-length":_vm.totalBranches,"loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.corporate.name)+" "),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'apps-user-view', params : { id : item.corporate.id } }}},[_vm._v(" "+_vm._s(item.corporate.name)+" ")]),_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.corporate.name))])],1)])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.corporate.employee_count))])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4 mt-3 float-right",attrs:{"color":"primary","dark":"","rounded":""},on:{"click":_vm.handleCreateGroup}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v(" Create Group ")],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"light":_vm.$vuetify.theme.light,"timeout":5000,"absolute":"","right":"","transition":"scroll-y-reverse-transition","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"error","text":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }