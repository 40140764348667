<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    fullscreen
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        rounded
        v-bind="attrs"
        v-on="on"
        class="mb-4"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        Create Group
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Create Group</span>
        <v-spacer></v-spacer>
        <v-icon
          class="mr-4"
          @click.stop="isDialogVisible = false">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="4">
              <v-autocomplete
                :items="facilities"
                label="Select a Facility"
                item-value="id"
                item-text="name"
                class="shrink"
                outlined
                dense
                v-model="formData.facility_id"
              >
               <template  v-slot:item="{ item }">
                  {{item.name}} 
                  <small class="ml-2">(<b>{{item.location}} </b>)</small>
                </template>
                  <template  v-slot:selection="{ item }">
                  {{item.name}} 
                  <small class="ml-2">(<b>{{item.location}} </b>)</small>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="5"
            >
              <p class="text--black">Add ungrouped recipients within</p>
              <v-radio-group
                row
                dense
                v-model="formData.distance"
              >
                <v-radio
                  label="5kms"
                  value="5"
                ></v-radio>
                <v-radio
                  label="10kms"
                  value="10"
                ></v-radio>
                <v-radio
                  label="15kms"
                  value="15"
                ></v-radio>
                <v-radio
                  label="20kms"
                  value="20"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="primary"
                class="pa-6 float-right"
                rounded
                @click="handleFindBranch"
              >
                Find Branches
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>
      <!-- table -->
      <div v-if="branches.length > 0"  class="mt-12">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="formData.name"
              outlined
              label="Group Name"
              placeholder="Group Name"
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
          <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="formData.date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              dense
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.date"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(formData.date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selectedRows"
          :headers="branchesColumns"
          :items="branches"
          :server-items-length="totalBranches"
          :loading="loading"
          show-select
        >
          <!-- name -->
          <template #[`item.name`]="{item}">
            <div class="d-flex align-center">
              {{ item.corporate.name }}
              <div class="d-flex flex-column ms-3">
                <router-link
                  :to="{ name : 'apps-user-view', params : { id : item.corporate.id } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.corporate.name }}
                </router-link>
                <span class="text-capitalize font-weight-semibold text--primary">{{ item.corporate.name }}</span>
              </div>
            </div>
          </template>

          <!-- employees -->
          <template #[`item.quantity`]="{item}">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.corporate.location }}</span>
          </template>

          <!-- employees -->
          <template #[`item.quantity`]="{item}">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.corporate.employee_count }}</span>
          </template>

        </v-data-table>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              dark
              rounded
              v-bind="attrs"
              v-on="on"
              class="mb-4 mt-3 float-right"
              @click="handleCreateGroup"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              Create Group
            </v-btn>
          </v-col>
        </v-row>

      </div>
        </v-container>
      </v-card-text>          
    </v-card>
    <!--snackbar-->
      <v-snackbar
        v-model="snackBar"
        :light="$vuetify.theme.light"
        :timeout="5000"
        absolute
        right
        transition="scroll-y-reverse-transition"
        top
      >
        {{ snackBarMessage }}
        <template #action="{ attrs }">
          <v-btn
            color="error"
            text
            v-bind="attrs"
            @click="snackBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

  </v-dialog>
</template>

<script>
  import { onMounted, ref, onUnmounted, watch } from '@vue/composition-api'
  import { mdiClose, mdiPlus } from '@mdi/js'
  import useCreateGroup from './useCreateGroup'
  import store from '@/store'
  import StoreModule from '../facilities/StoreModule.js'
  export default {
    name: 'create-group-modal',
    setup(props, { emit }) {
      const STORE_MODULE_NAME = 'facility'
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, StoreModule)
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })
      const isDialogVisible = ref(false)
      const {
        branches,
        branchesColumns,
        corporates,
        createMohGroup,
        facilities,
        groupCreated,
        fetchFacilities,
        findBranches,
        formData,
        loading,
        selectedRows,
        snackBar,
        snackBarMessage,
        totalBranches,
        useFacility,
      } = useCreateGroup()
      fetchFacilities()

      watch(selectedRows, value => {
        let corporateIds = []
        value.map(item => {
          corporateIds = [...corporateIds, item.id]
        })
        formData.corporates = [...corporateIds]
      })

      watch(groupCreated, value => {
        if (value) {
          isDialogVisible.value = false
          this.snackBar = true
          this.snackBarMessage = 'Group Created'
          emit('group-created')
        }
      })

      return {
        // corporates,
        // fetchCorporates,
        branches,
        branchesColumns,
        createMohGroup,
        facilities,
        fetchFacilities,
        findBranches,
        formData,
        isDialogVisible,
        loading,
        modal: false,
        selectedRows,
        snackBar,
        snackBarMessage,
        totalBranches,
        useFacility,
        icons: {
          mdiClose,
          mdiPlus,
        },
      }
    },
    methods: {
      handleFindBranch() {
        if (!this.formData.facility_id || !this.formData.distance) {
          this.snackBar = true
          this.snackBarMessage = 'Please select the facility and the radius'
          return
        }
        this.findBranches({ facilityId: this.formData.facility_id, radius: this.formData.distance })
      },
      handleCreateGroup() {
        if (this.formData.corporates.length < 1) {
          this.snackBar = true
          this.snackBarMessage = 'Please select a corporate to add to the request'
          return
        }
        if (!this.formData.name || !this.formData.date) {
          this.snackBar = true
          this.snackBarMessage = 'Please provide group name and vaccination date'
          return
        }

        const payload = {
          vaccination_date: this.formData.date,
          vaccination_time: '10:00',
          facility_id: this.formData.facility_id,
          corporate_ids: this.formData.corporates,
          location: 'Delta Riverside Office Park, Block A',
          name: 'Delta',
        }
        this.createMohGroup(payload)
      },
    },
  }
</script>