<template>
  <div>
    card window
    <v-card v-for="(group, i) in groupData" :key="group.facility_id" >
      {{group.facility_id}}
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'group-cards',
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      groupData: this.groups
    };
  },
  mounted(props) {
    console.log(props);
  },

}
</script>

<style>

</style>
