import store from '@/store'
import { reactive, ref, watch } from '@vue/composition-api'

export default function useCreateGroup() {
  const facilities = ref([])
  const corporates = ref([])
  const requests = ref([])
  const loading = ref(false)
  const useFacility = ref('facility')
  const branches = ref([])
  const totalBranches = ref([])
  const selectedRows = ref([])
  const snackBar = ref(false)
  const snackBarMessage = ref('')
  const groupCreated = ref(false)

  const formData = reactive({
    name: '',
    useFacility: true,
    facility_id: null,
    facility_name: '',
    facility_location: '',
    corporates: [],
    corporate_id: '',
    distance: null,
    date: '',
  })

  const branchesColumns = [
    { text: 'Corporates', value: 'name' },
    { text: 'location', value: 'location' },
    { text: 'Employees', value: 'employee_count' },
  ]

  const fetchFacilities = () => {
    loading.value = true
    store
      .dispatch('facility/fetchFacilities')
      .then(response => {
        facilities.value = response.data['result']
        loading.value = false
      })
      .catch(error => {
        facilities.value = []
        loading.value = false
      })
  }

  // const fetchCorporates = () => {
  //   loading.value = true
  //   store
  //     .dispatch('corporates/fetchCorporatesFromDB')
  //     .then(response => {
  //       corporates.value = response
  //       loading.value = false
  //     })
  //     .catch(error => {
  //       corporates.value = []
  //       loading.value = false
  //     })
  // }
  const findBranches = payload => {
    loading.value = true
    store
      .dispatch('corporates/findBranches', payload)
      .then(response => {
        if (response.data['message']) {
          snackBar.value = true
          snackBarMessage.value = response.data['message']
          return
        }
        branches.value = response.data['result']
        totalBranches.value = response.data['total']
        return response
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const createMohGroup = payload => {
    loading.value = true
    store
      .dispatch('corporates/createMohGroup', payload)
      .then(response => {
        if (response.status == 201) {
          groupCreated.value = true
        }
      })
      .catch(error => {})
      .finally(() => {
        loading.value = false
      })
  }

  const fetchVaccineRequests = () => {
    loading.value = true
    store
      .dispatch('corporates/fetchVaccineRequests')
      .then(response => {
        requests.value = response
        loading.value = false
      })
      .catch(error => {
        requests.value = []
        loading.value = false
      })
  }

  const getAllRequestWithinRadius = () => {
    const distance = formData.value.distance
    const facility = facilities.value.find(item => item.id == formData.value.facility_id)
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  const calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371 // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var lat1 = toRad(lat1)
    var lat2 = toRad(lat2)

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }

  // Converts numeric degrees to radians
  const toRad = Value => {
    return (Value * Math.PI) / 180
  }

  watch([formData], () => {
    console.log(formData.value)
  })

  return {
    // fetchCorporates,
    groupCreated,
    branches,
    branchesColumns,
    corporates,
    createMohGroup,
    facilities,
    fetchFacilities,
    findBranches,
    formData,
    loading,
    selectedRows,
    snackBar,
    snackBarMessage,
    totalBranches,
    useFacility,
  }
}
