<template>
  <div>
    <!-- alert colors-->
    <v-card
      title="Colors"
      class="mb-6"
    >
      <v-card-title>
        Groups
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            rounded
            outlined
            color="primary"
            class="mb-4 me-3"
            @click="confirmVaccineRequest"
            :disabled="selectedRows.length < 1"
            >Request for Vaccines</v-btn>
          <create-group-modal :group-created="handleGroupCreated"/>
        </div>
      </v-card-text>

    <v-data-table
      v-model="selectedRows"
      :headers="tableColumns"
      :items="groups"
      :server-items-length="totalGroups"
      :loading="loading"
      show-select
      >
      <template #[`item.status_name`]="{item}">
          <v-chip
            small
            :color="resolveRequestStatusColor(item.status_name)"
            :class="`${resolveRequestStatusColor(item.status_name)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            Pending
          </v-chip>
        </template>
      <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item

              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirm Request
        </v-card-title>
        <div class="ml-3">
          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Facilities:</span>
              <span class="text--secondary">{{ selectedFacilities }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2 d-flex"
            >
              <span class="font-weight-medium me-2">Corporates:</span>
                  <span
                      v-for="(corporate, index) in requestCorporates"
                      :key="index"
                      class="my-0"
                    >
                    {{ corporate.coporate }} <small>({{corporate.location}}) ,</small>
                  </span>
            </v-list-item>

             <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Total Requests:</span>
              <span class="text--secondary">{{ requestVaccines }}</span>
            </v-list-item>
          </v-list>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handleCancle"
          >
            Cancel
          </v-btn>
          <v-btn
            outlined
            color="success"
            text
            @click="handleRequestVaccines"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <!--snackbar-->
    <v-snackbar
      v-model="snackbar.snackbar"
      :light="$vuetify.theme.light"
      :timeout="5000"
      absolute
      right
      transition="scroll-y-reverse-transition"
      top
    >
      {{ snackbar.message }}
      <template #action="{ attrs }">
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  
  </div>
</template>

<script>
  import store from '@/store'
  import { onMounted, onUnmounted, onBeforeUpdate, ref, watch, computed } from '@vue/composition-api'
  import {
    mdiSquareEditOutline,
    mdiDotsVertical,
    mdiPlus,
    mdiFileDocumentOutline,
    mdiDeleteOutline,
    mdiExportVariant,
    mdiAccountOutline,
    mdiPlaylistEdit,
  } from '@mdi/js'
  import useGroupList from './useGroupList'
  import groupStoreModule from './groupStoreModule'
  import GroupCards from './GroupCards'
  import CreateGroupModal from './CreateGroupModal.vue'

  export default {
    name: 'GroupList',
    components: {
      GroupCards,
      CreateGroupModal,
    },
    setup() {
      // Handle store module
      const GROUP_STORE_MODULE_NAME = 'groups'

      if (!store.hasModule(GROUP_STORE_MODULE_NAME)) store.registerModule(GROUP_STORE_MODULE_NAME, groupStoreModule)

      onUnmounted(() => {
        if (store.hasModule(GROUP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_STORE_MODULE_NAME)
      })

      const divs = ref([])

      // Make sure to reset the refs before each update.
      onBeforeUpdate(() => {
        divs.value = []
      })

      const {
        requestTable,
        tableColumns,
        options,
        totalRequests,
        selectedRows,
        loading,
        groups,
        totalGroups,
        snackbar,
        fetchCorporateRequests,
        resolveRequestStatusColor,
        fetchGroups,
        makeVaccineRequest,
        resolveFacilities,
        facilities,
      } = useGroupList()

      onMounted(() => {
        fetchCorporateRequests()
        fetchGroups()
      })

      let selectedFacilities = computed(() => {
        let selectedFacility = ''
        selectedRows.value.map(item => {
          selectedFacility += item.location
        })
        return selectedFacility
      })

      let requestVaccines = computed(() => {
        let totalVaccines = 0
        selectedRows.value.map(item => {
          totalVaccines += item.quantity
        })
        return totalVaccines
      })

      let requestCorporates = computed(() => {
        let selectedCorporates = []
        selectedRows.value.map(groups => {
          groups.corporates.map(items => {
            selectedCorporates = [
              ...selectedCorporates,
              { coporate: items.vaccine_request.branch.corporate.name, location: items.vaccine_request.branch.location },
            ]
          })
        })
        return selectedCorporates
      })

      return {
        selectedFacilities,
        requestCorporates,
        requestVaccines,
        requestTable,
        tableColumns,
        options,
        totalRequests,
        selectedRows,
        loading,
        groups,
        totalGroups,
        facilities,
        snackbar,
        makeVaccineRequest,
        fetchCorporateRequests,
        resolveRequestStatusColor,
        fetchGroups,
        resolveFacilities,
        dialog: false,
        icons: {
          mdiSquareEditOutline,
          mdiFileDocumentOutline,
          mdiDotsVertical,
          mdiDeleteOutline,
          mdiPlus,
          mdiExportVariant,
          mdiAccountOutline,
          mdiPlaylistEdit,
        },
      }
    },
    methods: {
      confirmVaccineRequest() {
        this.dialog = true
      },
      handleRequestVaccines() {
        this.dialog = false

        let groupIds = []

        this.selectedRows.map(row => {
          groupIds = [...groupIds, row.id]
        })

        let payload = {
          vaccine_type_id: 1,
          group_ids: groupIds,
          quantity: 4000,
        }

        this.makeVaccineRequest(payload)
      },
      handleCancle() {
        this.dialog = false
      },
      handleGroupCreated() {
        this.fetchGroups()
        // this.snackbar = {
        //   ...this.snackbar,
        //   snackbar: true,
        //   message: 'Group created successfully',
        // }
      },
    },
  }
</script>
