import axios from 'axios'
import env from '../../env'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGroups(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${env.chmApp.baseUrl}/api/v1/group`)
          .then(response => {
            let groups = []
            for (let i = 0; i < response.data['result'].length; i++) {
              const group = response.data['result'][i]
              let count = 0
              for (let index = 0; index < group.corporates.length; index++) {
                const corp = group.corporates[index]
                count += corp.vaccine_request['quantity']
              }
              group['quantity'] = count
              groups.push(group)
            }
            resolve(groups)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchFromCHM(ctx) {
      return new Promise((resolve, reject) => {
        resolve('data found')
      })
    },
    makeVaccineRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${env.chmApp.baseUrl}/api/v1/moh-request`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
  },
}
