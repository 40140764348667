import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedFacility: {},
  },
  getters: {},
  mutations: {
    addSelectedUser(state, item) {
      state.selectedFacility = item
    },
  },
  actions: {
    fetchFacilities(ctx, queryParams) {
      let filterString = ''
      if (queryParams) {
        const limit = queryParams?.options.itemsPerPage ?? 25
        const offset = (queryParams?.options.page - 1) * limit
        const sort = queryParams.options.sortDesc.length == 1 ? 'sort_asc' : 'sort_desc'
        const filter = queryParams.q == '' ? '' : '&name=' + queryParams.q

        filterString = `?limit=${limit}&offset=${offset}&sort_by=name&${sort}=true${filter}`
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`/facility${filterString}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFacility(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/facility/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
