import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCorporatesList() {
  const tableColumns = [
    { text: 'FACILITY', value: 'facility.name' },
    { text: 'RECEPIENTS', value: 'quantity' },
    { text: 'LOCATION', value: 'location' },
    { text: 'VACCINATION DATE', value: 'vaccination_date' },
    { text: 'VACCINATION TIME', value: 'vaccination_time' },
    { text: 'STATUS', value: 'status_name' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  let snackbar = ref({
    snackbar: false,
    message: '',
  })

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const requestTable = ref([])
  const totalRequests = ref(0)
  const selectedRows = ref([])
  const loading = ref(false)
  const groups = ref([])
  const totalGroups = ref(0)
  const facilities = ref([])
  // fetch requests
  const fetchCorporateRequests = () => {
    loading.value = true
    store
      .dispatch('corporates/fetchVaccineRequests')
      .then(response => {
        requestTable.value = response
        totalRequests.value = response.length
      })
      .catch(error => {
        requestTable.value = []
        totalRequests.value = 0
      })
      .finally(() => {
        loading.value = false
      })
  }
  // fetch groups
  const fetchGroups = () => {
    loading.value = true
    // fetch facilities
    store.dispatch('facility/fetchFacilities').then(response => {
      facilities.value = response.data['result']
      store
        .dispatch('groups/fetchGroups')
        .then(response => {
          groups.value = response
          totalGroups.value = response.length
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  const createGroup = payload => {
    loading.value = true
    store
      .dispatch('corporates/fetchVaccineRequests')
      .then(response => {
        requestTable.value = response
        totalRequests.value = response.length
        loading.value = false
      })
      .catch(error => {
        requestTable.value = []
        totalRequests.value = 0
        loading.value = false
      })
  }

  watch([options, selectedRows], () => {
    loading.value = true
    fetchCorporateRequests()
  })

  const makeVaccineRequest = payload => {
    loading.value = true
    store
      .dispatch('groups/makeVaccineRequest', payload)
      .then(response => {
        snackbar.value = { ...snackbar.value, snackbar: true, message: 'Request made successfully' }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
      })
  }
  const resolveFacilities = uuid => {
    const facility = facilities.value.find(item => item.id === uuid)
    return facility
  }

  // UI
  const resolveRequestStatusColor = status => {
    if (status === 'Pending') return 'warning'
    else if (status === 'Approved') return 'success'
    else if (status === 'Rejected') return 'error'
    else return 'info'
  }

  return {
    requestTable,
    tableColumns,
    options,
    totalRequests,
    selectedRows,
    loading,
    groups,
    totalGroups,
    facilities,
    snackbar,
    makeVaccineRequest,
    createGroup,
    fetchCorporateRequests,
    resolveRequestStatusColor,
    fetchGroups,
    resolveFacilities,
  }
}
